<template>
    <div id="loginPage" class="pt-3">
        <Carregamento v-if="!live" />
        <transition mode="out-in" v-else>
            <div class="containerAll mt-5 pt-4 mb-4">
                <!-- <Logo /> -->
                
                <div v-if="novoAcesso == 1 && (live.login.config.loginWithCadastro=='true'|| live.login.config.onlyLogin=='true')" class="box-login pt-5">
                    <CenterLogo/>
                    <!-- <b-card> -->
                    <div class="container">
                        <div class="">
                            <div class="col-12  px-md-3 p-0 mx-auto mx-md-0">
                                <b-form @submit="onSubmit" v-if="show">

                                    <h1 class="h1 text-center text-white m-0" >{{txt.title}}</h1>
                                    <p class="p text-center text-white" >{{txt.caption}}</p>
                                    <!-- <p>¡Bienvenido! Ingrese su correo electrónico para acceder a la transmisión.</p> -->
                                    <b-alert v-if="alerta" show variant="danger">
                                       {{txt.errors[`${currentError}`]}}
                                       <!-- <br v-if="live.login.config.loginWithCadastro == 'true'" /><br v-if="live.login.config.loginWithCadastro == 'true'" /> 
                                        <c v-if="live.login.config.loginWithCadastro == 'true'">Não tem acesso? Clique <a @click.prevent="novoAcesso = 2"
                                            href="#">aqui</a> e solicite um!</c> -->
                                    </b-alert>

                                    <input type="text" class="form-control mb-2"  v-model="form.name" :placeholder="txt.inputName" required>
                                    
                                    <b-form-input id="password" class="form-control mb-2" v-model="form.email" type="text"
                                        :placeholder="txt.inputEmail" required>
                                    </b-form-input>

                                    <input type="text" class="form-control mb-2"  v-model="form.company" :placeholder="txt.inputCompany" required>

                                    <!-- <div class="d-flex justify-content-center my-3">
                                        <vue-recaptcha @expired="resetRecaptcha" @verify="recaptchaVerified" sitekey="6LeDK4geAAAAAMNo7qvE7c5Ai0NhrKbNpmFbIVXg" ref="recaptchaRef" :loadRecaptchaScript="true"></vue-recaptcha>
                                    </div> -->

                                    <div class="text-center mb-4" style="position: relative; z-index:1000">
                                        <button :disabled="btnLogin.disabled" :style="btnStyles" class="btn myBtn w-100"
                                            type="submit">{{txt.btnTxt}}</button>
                                    </div>
                                    <div class="text-center " v-if="live.login.config.loginWithCadastro=='true'">
                                        <a class="sol-acesso text-white" @click.prevent="novoAcesso = 2" href="#">Solicitar acesso</a>
                                    </div>
                                </b-form>
                            </div>
                        </div>
                    </div>
                    <!-- </b-card> -->
                </div>
                <div class="box-login" v-if="novoAcesso == 2 || live.login.config.onlyCadastro=='true'">
                    <!-- <b-card> -->
                    <div class="container h-100">
                        <div v-if="this.live.login.config.final == 'false' && this.live.login.config.embreve == 'false'" class="row row-form justify-content-between align-items-center">
                            <div class="col col-12 mb-4">
                                <img src="../assets/img/logo.png" class="logo50anos w-100 mx-auto d-block mt-3 mt-lg-0" alt="PETRONAS">
                            </div>

                            <div class="col col-12">
                                <div class="mx-auto" style="max-width: max-content;">
                                    <div class="boxLogin">
                                        <h1 class="titleBox text-center m-0 mb-2">{{live.login.config.loginWithCadastro=='true' ? 'Novo acesso' : 'Login'}}</h1>
                                        <p class="textBox text-center mb-3" >Preencha as informações abaixo para ingressar no evento.</p>
                                        <b-form @submit="solicita">
                                            <b-alert v-if="alertaSolicita.status" show :variant="alertaSolicita.variant">
                                                {{alertaSolicita.msg}}
                                            </b-alert>
    
                                            <b-form-group id="nomeSolicita" class="mb-3" label-for="nomeSolicitaForm">
                                                <b-form-input id="nomeSolicitaForm" v-model="formSolicita.name" type="text"
                                                    placeholder="Nome" required>
                                                </b-form-input>
                                            </b-form-group>
    
                                            <b-form-group id="nomeSolicita" class="mb-3" label-for="nomeSolicitaForm">
                                                <b-form-input id="nomeSolicitaForm" v-model="formSolicita.surname" type="text"
                                                    placeholder="Sobrenome" required>
                                                </b-form-input>
                                            </b-form-group>
    
                                            <b-form-group id="emailSolicita" class="mb-3" label-for="emailSolicitaForm">
                                                <b-form-input id="emailSolicitaForm" v-model="formSolicita.email" type="email"
                                                    placeholder="E-mail corporativo" required>
                                                </b-form-input>
                                            </b-form-group>
    
                                            <!-- <b-form-group id="telefoneSolicita" class="mb-2" label-for="telefoneSolicitaForm">
                                                <b-form-input id="telefoneSolicitaForm" v-model="formSolicita.phone" type="text"
                                                    placeholder="Seu Telefone" required>
                                                </b-form-input>
                                            </b-form-group> -->
    
                                            <!-- <b-form-group id="empresaSolicita" class="mb-2" label-for="empresaSolicitaForm">
                                                <b-form-input id="empresaSolicitaForm" v-model="formSolicita.company" type="text"
                                                    placeholder="Empresa" required>
                                                </b-form-input>
                                            </b-form-group> -->
    
                                            <b-input-group class="mb-3">
                                                <b-form-select id="input-3" v-model="formSolicita.company" :options="distributors" required></b-form-select>
                                                <template #append>
                                                    <div class="d-flex align-items-center aside">
                                                        <b-icon icon="triangle-fill" aria-hidden="true"></b-icon>
                                                    </div>
                                                </template>
                                            </b-input-group>
                                            
                                            <b-input-group>
                                                <b-form-select id="input-3" v-model="formSolicita.office" :options="offices" required></b-form-select>
                                                <template #append>
                                                    <div class="d-flex align-items-center aside">
                                                        <b-icon icon="triangle-fill" aria-hidden="true"></b-icon>
                                                    </div>
                                                </template>
                                            </b-input-group>
    
                                            <!-- <b-form-group id="indicouSolicita" label-for="indicouSolicitaForm" class="d-none">
                                                <b-form-input id="indicouSolicitaForm" v-model="formSolicita.indication" type="text"
                                                    placeholder="Quem indicou" required>
                                                </b-form-input>
                                            </b-form-group> -->
    
                                            <!-- <div class="d-flex justify-content-center my-3">
                                                <vue-recaptcha @expired="resetRecaptcha" @verify="recaptchaVerified" sitekey="6LeDK4geAAAAAMNo7qvE7c5Ai0NhrKbNpmFbIVXg" ref="recaptchaRef" :loadRecaptchaScript="true"></vue-recaptcha>
                                            </div> -->
    
                                            <div class="d-flex justify-content-center">
                                                <button v-if="live.login.config.loginWithCadastro=='true'" :style="btnStyles" class="btn w-45 myBtn" type="button" @click.prevent="novoAcesso = 1">Voltar</button>
                                                <button :disabled="btnSolicita.disabled" :style="btnStyles" class="btn w-45 myBtn mt-3"
                                                    type="submit">{{btnSolicita.message}}</button>
                                            </div>
    
                                        </b-form>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-else-if="this.live.login.config.final == 'false'" class="h-100 d-flex flex-column justify-content-center align-items-center">
                            <div class="mt-5 pt-3">
                                <img src="../assets/img/logo.png" class="logo50anos w-100 mx-auto d-block mt-3 mt-lg-0" alt="PETRONAS">
    
                                <p class="textointro d-block">EM MREVE</p>
                                <p class="textointrodata d-block">07/10 _ às 8h30</p>
                            </div>
                        </div>

                        <div v-else class="h-100 d-flex flex-column justify-content-center align-items-center">
                            <div>
                                <img src="https://www.cantalive.com.br/petronas-50-anos-logo.png" class="logo50anos w-100 mx-auto d-block mt-3 mt-lg-0" alt="PETRONAS">
    
                                <p class="textointro d-block">GRACIAS</p>
                            </div>
                        </div>
                    </div>
                    <!-- </b-card> -->
                </div>
                <div v-else-if="novoAcesso == 3" class="box-login">
                    <b-card>
                        <h1>{{telaFinal.h1}}</h1>
                        <p>{{telaFinal.p}}</p>
                        <div class="text-center">
                            <button :style="btnStyles" class="btn" type="button" @click.prevent="novoAcesso = 2">Voltar</button>
                        </div>
                    </b-card>
                </div>
                <div v-else-if="novoAcesso == 4" class="box-login">
                    <b-card>
                        <h1>{{telaFinal.h1}}</h1>
                        <p>{{telaFinal.p}}</p>
                        <!-- {{novoAcesso}} -->
                        <div class="text-center">
                            <button v-if="live.login.config.loginWithCadastro=='true'" :style="btnStyles" class="btn" type="button" @click.prevent="novoAcesso = 1">Voltar a tela inicial</button>
                            <button v-else :style="btnStyles" class="btn" type="button" @click.prevent="novoAcesso = 2">Voltar a tela inicial</button>
                        </div>
                    </b-card>
                </div>
            </div>
           
        </transition>
        <!-- <LogoBottom :margin="80"/> -->
        <Footer v-if="live.login.config.embreve == 'false'" :lang="lang"/>
    </div>
</template>

<script>
    import {
        api
    } from "@/services.js";
    import {
        mapState
    } from 'vuex';
    // import VueRecaptcha from 'vue-recaptcha';
    import Footer from '@/components/Login/Footer.vue';
    // import Logo from '@/components/Login/Logo.vue';
    // import LogoBottom from '@/components/Login/LogoBottom.vue';

    export default {
        props: ['password','lang'],
        name: "login",
        components: {
            Footer,
            // Logo,
            // LogoBottom
        },
        data() {
            return {
                recVerify: false,
                currentError:null,
                form: {
                    email: this.password
                },
                formSolicita: {
                    name: "",
                    surname: "",
                    email: "",
                    phone: "",
                    company: null,
                    office: null,
                    indication: ""
                },
                btnSolicita: {
                    message: '',
                    disabled: false
                },
                telaFinal: {
                    h1: null,
                    p: null
                },
                show: true,
                btnStyles: {},
                btnLogin: {
                    message: "ENTRAR",
                    // MUDAR AQUIIII
                    disabled: true,
                    btn: null
                },
                alerta: false,
                novoAcesso: 1,
                alertaSolicita: {
                    status: false,
                    msg: '',
                    variant: ''
                },
                txt: {
                    title: '',
                    caption: '',
                    inputName: '',
                    inputEmail: '',
                    btn: '',
                    btnTxt: '',
                    btnSend: '',
                    alert: ''
                },
                distributors: [],
                offices: [],
            }
        },
        computed: {
            ...mapState(['live'])
        },
        methods: {
            async resetRecaptcha() {
                await this.$refs.recaptchaRef.reset();
                this.btnLogin.disabled = true
            },
            recaptchaVerified(event) {
                this.form['g-recaptcha-response'] = event
                this.formSolicita['g-recaptcha-response'] = event
                this.btnLogin.disabled = false
            },   
            redirectToWaiting(){   
                if(this.$route.params.lang == "en"){
                    this.$router.push(`/${this.lang}`)
                    
                } 
                // else if(this.live.waiting[`${this.lang}`] == 'true' || this.live.waiting[`${this.lang}`]){
                //     this.$router.push(`/waiting/${this.lang}`)
                // }
                else if(this.live.login.config.state=='public') {
                    this.$router.push(`live/${this.lang}`)
                }
            },
            async onSubmit(event) {
                if(this.live.login.config.onlyCadastro=='false') {
                    event.preventDefault()
                    this.btnLogin.disabled = true;
                    this.txt.btnTxt = this.txt.btnSend;
                    this.alerta = false;
                }
                else {
                    // this.alertaSolicita.status = false;
                    this.btnSolicita.disabled = true;
                    this.btnSolicita.message = 'ENTRANDO'
                }

                this.form.liveId = this.$store.state.live.id
                this.form.lang = this.lang
                this.form.originBy = 'Login'
                this.form.email = this.formSolicita.email

                await api.login(this.form).then(async res => {
                    this.currentError = `error${parseInt(res.data.code)}`
                    
                    switch(parseInt(res.data.code)) {
                        case 1:
                            this.$store.commit("UPDATE_ID_USER", res.data.userId);
                            window.localStorage.token = res.data.userId
                            window.localStorage.mail = this.form.email
                            this.$store.commit("UPDATE_LOGIN", true);
                            if(this.lang=='pt') {
                                this.$gtag.event('Login made in pt', { method: 'Google' })
                            }else if(this.lang=='esp') {
                                this.$gtag.event('Login made in esp', { method: 'Google' })
                            }else {
                                this.$gtag.event('Login made in other', { method: 'Google' })
                            }
                            this.$router.push(`/live/${this.live.id}/${this.lang}`);
                        break;
                        case 2:
                            this.btnLogin.disabled = true;
                            this.txt.btnTxt = this.txt.btn;
                            this.alerta = true;
                            this.$gtag.event('Login failed - domain not allowed', { method: 'Google' })
                            // this.resetRecaptcha()
                        break;
                        case 3:
                            console.log('caso 3')
                            this.btnLogin.disabled = true;
                            this.txt.btnTxt = this.txt.btn;
                            this.alerta = true;
                            // await this.resetRecaptcha()
                            this.solicita()
                        break;
                        case 4:
                            this.btnLogin.disabled = true;
                            this.txt.btnTxt = this.txt.btn;
                            this.alerta = true;
                            // this.resetRecaptcha()
                        break;
                        case 5:
                            this.btnLogin.disabled = true;
                            this.txt.btnTxt = this.txt.btn;
                            this.alerta = true;
                            // this.resetRecaptcha()
                        break;
                        case 6:
                            this.btnLogin.disabled = true;
                            this.txt.btnTxt = this.txt.btn;
                            this.alerta = true;
                            this.resetRecaptcha()
                        break;
                        case 7:
                            this.btnLogin.disabled = true;
                            this.txt.btnTxt = this.txt.btn;
                            this.alerta = true;
                            this.resetRecaptcha()
                        break;
                        case 8:
                            this.btnLogin.disabled = true;
                            this.txt.btnTxt = this.txt.btn;
                            this.alerta = true;
                            this.resetRecaptcha()
                        break;
                        case 9:
                            this.btnLogin.disabled = true;
                            this.txt.btnTxt = this.txt.btn;
                            this.alerta = true;
                            this.resetRecaptcha()
                        break;
                        case 10:
                            this.alertaSolicita.msg = 'Preencha o recaptcha'
                            this.alertaSolicita.variant = 'warning'
                            this.alertaSolicita.status = true
                            this.resetRecaptcha()
                        break;
                    }

                    // console.log(response.data)
                    // if(response.data.id){
                    //     window.localStorage.id_User = response.data.id
                    //     this.$store.commit("UPDATE_ID_USER", response.data.id);
                    // }
                    // if (!response.data.token) {
                    //     if(this.live.login.config.onlyCadastro=='false') {
                    //         this.btnLogin.disabled = false;
                    //         this.txt.btnTxt = this.txt.btn;
                    //         this.alerta = true;
                    //     }else {
                    //         this.btnSolicita.disabled = false;
                    //         this.txt.btnTxt = this.txt.btn;
                    //         this.alertaSolicita = true;
                    //     }
                    // } else {
                    //     window.localStorage.token = response.data.token
                    //     this.$store.commit("UPDATE_LOGIN", true);
                    //     this.$router.push(`/live/${this.live.id}/${this.lang}`);
                    // }
                    // this.$router.push(`/live/${this.live.id}/${this.lang}`);
                });

            },
            async solicita(event) {
                console.log('solicita')
                if(event)
                    event.preventDefault()

                this.btnSolicita.disabled = true;
                this.live.login.config.loginWithCadastro=='true' ? this.btnSolicita.message = 'Enviando' : this.btnSolicita.message = 'ENTRANDO'

                this.formSolicita.config = this.live.login.config
                this.formSolicita.liveId = this.live.id
                
                await this.$recaptchaLoaded()
                const token = await this.$recaptcha('login')
                this.form['g-recaptcha-response'] = token
                this.formSolicita['g-recaptcha-response'] = token

                await api.solicitation(this.formSolicita).then(response => {

                    //usuario já cadastrado
                    if (response.data.code == 102) {
                        this.telaFinal.h1 = 'E-mail já cadastrado!';
                        this.telaFinal.p = 'Esse email já conta em nossos cadastros.';
                        this.telaFinal.btn = true;
                        this.novoAcesso = 3;

                        //cadastrado com sucesso
                    } else if (response.data.code == 100) {
                        this.telaFinal.h1 = 'Solicitação envidada!';
                        this.telaFinal.p = 'Em breve você irá receber um e-mail com os dados de acesso!';
                        this.novoAcesso = 4;
        

                        //falha ao cadastrar
                    } else if (response.data.code == 101) {
                        this.telaFinal.h1 = 'Falha ao cadastrar';
                        this.telaFinal.p = 'Algo de errado aconteceu. Tente novamente';
                        this.telaFinal.btn = true;
                        this.novoAcesso = 4;

                        // Cadastro retornando a senha 
                    } else if(response.data.code == 103 || response.data.code == 105 ){
                        this.alertaSolicita.msg = 'Redirecionando...'
                        this.alertaSolicita.variant = 'success'
                        this.alertaSolicita.status = true
                        this.form.password = response.data.token
                        this.onSubmit();

                        //erro 500
                    }else if(response.data.code == 10) {
                        this.alertaSolicita.msg = 'Preencha o recaptcha'
                        this.alertaSolicita.variant = 'warning'
                        this.alertaSolicita.status = true
                    }else {
                        this.telaFinal.h1 = 'Falha ao cadastrar';
                        this.telaFinal.p = 'Algo de errado aconteceu. Tente novamente';
                        this.telaFinal.btn = true;
                        this.novoAcesso = 4;
                    }

                    
                    this.btnSolicita.disabled = false;
                    this.btnSolicita.message = 'Entrar'


                });
                
            },
            setStyle() {
                let obj = this.$store.state.live;
                let prefix = obj.login;

                switch (this.lang) {
                    case 'pt':
                        this.txt.title = prefix.texts.pt.title
                        this.txt.caption = prefix.texts.pt.caption
                        this.txt.inputName = prefix.texts.pt.inputName
                        this.txt.inputEmail = prefix.texts.pt.inputEmail
                        this.txt.inputCompany = prefix.texts.pt.inputCompany
                        this.txt.inputOffice = prefix.texts.pt.inputOffice
                        this.txt.btn = prefix.texts.pt.btn
                        this.txt.btnSend = prefix.texts.pt.btnSend
                        this.txt.alert = prefix.texts.pt.alert
                        this.txt.errors = prefix.texts.pt.errors

                        this.txt.btnTxt = this.txt.btn
                        break;
                    case 'esp':
                        this.txt.title = prefix.texts.esp.title
                        this.txt.caption = prefix.texts.esp.caption
                        this.txt.inputName = prefix.texts.esp.inputName
                        this.txt.inputEmail = prefix.texts.esp.inputEmail
                        this.txt.inputCompany = prefix.texts.esp.inputCompany
                        this.txt.inputOffice = prefix.texts.esp.inputOffice
                        this.txt.btn = prefix.texts.esp.btn
                        this.txt.btnSend = prefix.texts.esp.btnSend
                        this.txt.alert = prefix.texts.esp.alert
                        this.txt.errors = prefix.texts.esp.errors

                        this.txt.btnTxt = this.txt.btn
                        break;
                    case 'en':
                        this.txt.title = prefix.texts.en.title
                        this.txt.caption = prefix.texts.en.caption
                        this.txt.inputName = prefix.texts.en.inputName
                        this.txt.inputEmail = prefix.texts.en.inputEmail
                        this.txt.btn = prefix.texts.en.btn
                        this.txt.btnSend = prefix.texts.en.btnSend
                        this.txt.alert = prefix.texts.en.alert

                        this.txt.btnTxt = this.txt.btn
                        break;
                    default:
                        this.txt.title = prefix.texts.pt.title
                        this.txt.caption = prefix.texts.pt.caption
                        this.txt.inputName = prefix.texts.pt.inputName
                        this.txt.inputEmail = prefix.texts.pt.inputEmail
                        this.txt.btn = prefix.texts.pt.btn
                        this.txt.btnSend = prefix.texts.pt.btnSend
                        this.txt.alert = prefix.texts.pt.alert

                        this.txt.btnTxt = this.txt.btn
                        break;
                }

               this.btnSolicita.message = 'Entrar'

                // document.body.style.cssText =`background: url(${prefix.body.styles.backgroundImage})`;
                document.body.style.cssText =`background: url(${require('../assets/img/bg-2.png')}) ${prefix.body.styles.backgroundColor}`;

                this.btnStyles = prefix.button;

                var distWithSelect = [{text:'Distribuidor Autorizado',value:null},...prefix.distributors]
                var officesWithSelect = [{text:'Cargo',value:null},...prefix.offices]

                this.distributors = distWithSelect ?? []
                this.offices = officesWithSelect ?? []

            },
            getLive() {
                this.$store
                    .dispatch('getLive', this.lang)
                    .then(() => {
                        if(this.live.login.config.state == 'public' && this.live.waiting[`${this.lang}`]!='true') {
                            this.$router.push(`/live/${this.lang}`)
                        }
                        // else if(this.live.waiting == 'true') {
                        //     this.$router.push('/waiting')
                        // }
                        this.setStyle();
                    })
                    .catch(error => {
                        console.log(error);
                    })  

                
            },
            setLang(){
                this.$route.params.lang ? this.$store.commit('UPDATE_LANG', this.$route.params.lang) : this.$store.commit('UPDATE_LANG', 'pt')
            }
        },
        watch: {
            live(){
                this.redirectToWaiting();
            },
            lang() {
                this.setLang()
                this.getLive()
            }
        },
        created() {
            this.setLang()
            this.getLive()
        }
    }
</script>


<style>
body{
    background-attachment: scroll;
}
</style>
<style scoped>
.logo50anos{
    max-width: 400px;
}
@media (max-width: 991px) {
    .logo50anos{
        max-width: 150px;
    }
}

.textointro{
    text-align: center;
    display: block;
    color: #fff;
    font-size: 60px;
    margin-bottom: 0;
    margin-top: 80px;
}
.textointrodata{
    text-align: center;
    display: block;
    color: #feda90;
    font-size: 60px;
    margin-bottom: 0;
}



#loginPage{
    /*background-image: url("../assets/img/tickets.png");*/
    /*background-repeat: no-repeat;*/
    /*background-size: cover;*/
    /*background-position: top;*/
}

.h1{
    font-family: 'museo-sans-900';
    font-size: 75px;
}
.row-form{
    /* padding: 100px 0; */
}
.p{
    font-family: 'museo-sans';
    font-size: 23px;
}

input, select{
    font-family: 'museo-sans';
    border: 1px solid #A2A2A2;
    border-radius: 10px;
    background: #F5F5F5;
}
input:focus, select:focus{
    border-color: #1ea69b;
    box-shadow: none;
}

select {
    border-right: 0;
}
.myBtn{
    border: none;
    border-radius: 10px;
    background: #1ea69b !important;
    font-family: 'museo-sans-900';
    text-transform: uppercase;
    padding: 10px 15px;
}
.w-45{
    width: 45%;
}
@media(max-width: 1200px){
    .h1{font-size: 63px;}
    .p{font-size: 19px;}
}
@media(max-width: 991px){
    .h1{font-size: 56px;}
    .p{font-size: 17px;}
    input{ border-radius: 8px;}
    .myBtn{border-radius: 8px;}
}
@media(max-width: 767px){
    /* .row-form{padding: 100px 0 50px 0;} */
    .p{
        font-size: 15px;
        width: 200px;
        margin-left: auto;
        margin-right: auto;
    }

  .container{
    padding-top: 80px;
  }
}

@media(max-width: 428px){
    .h1{font-size: 44px;}
}
  
    .textLogin, .textDesc {
        color: #00A184;
        font-weight: 700;
    }

    .lineFru {
        background: #00A184;
        border-radius: 32px;
        width: 150px;
        height: 10px;
        transform: skewX(-25deg);
    }

    /* input:not(input[type="submit"]) {
        border: 2px solid #00A184;
    } */
    .containerAll {
        /* min-height: calc(100vh - 88px); */
    }

    @media(max-width:767px) {
        .containerAll {
            min-height: calc(100vh - 108px);
        }
    }

    @media(max-width:423px) {
        .containerAll {
            min-height: calc(100vh - 72.2px);
        }
    }

    .titleBox, .textBox {
        color: #1ea69b;
    }

    .titleBox {
        font-family: 'museo-sans-900';
    }

    .textBox {
        font-family: 'museo-sans';
    }

    .boxLogin {
        background: #FFFFFF;
        padding: 50px;
        border-radius: 18px;
        border: 1px solid #1ea69b;
    }

    @media(max-width:575px) {
    .row-form{padding: 0 0 50px 0;}
    .boxLogin {
        padding: 30px 15px;
    }
}

.aside {
    background: #F5F5F5;
    border: 1px solid #A2A2A2;
    padding: 0 10px;
    border-radius: 0 10px 10px 0px;
}

.aside svg {
    transform: rotate(180deg);
    width: 10px;
    color: #8a8a8a;
}
</style>