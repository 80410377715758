<template>
  <footer :style="live.login.footer.styles" v-if="live!=null">
    <p class="textFooter text-center">
      © PETRONAS Lubrificantes Brasil. Todos os direitos reservados.
    </p>

  </footer>
</template>

<script>
import { mapState } from 'vuex'
export default {
name: 'Footer',
props:['lang'],
computed: {
  ...mapState(['live'])
}
}
</script>

<style  scoped>
    .textFooter {
      /* color: #00A184; */
      color: #fff;
      font-size: 1rem;
      font-weight: 700;
      font-family: 'museo-sans';
      margin-bottom: 20px;
    }

    .line {
      background: #E9B61A;
      height: 39px;
      width: 100%;
    }

    .white{
      background-color: #FFF ;
    }

    .green{
      background-color: #046b3b ;
    }

    .red{
      background-color: #ff3414 ;
    }

    @media(max-width:767px) {
        .textFooter {
          font-size: .9rem;
        }
    }   
</style>